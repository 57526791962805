@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Custom Colors
$primary: #ff0090 !default;
$white: #eeeeee !default;
$black: #000 !default;

// Custom Images
$header-image: "../img/header.jpg";
$contact-image: "../img/contact.png";

// Global CSS
@import "base/page.scss";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";
@import "components/client-scroll.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/services.scss";
@import "layout/portfolio.scss";
@import "layout/timeline.scss";
@import "layout/team.scss";
@import "layout/contact.scss";
@import "layout/footer.scss";

.store-logo {
  margin-top: 10px;
  margin-bottom: 25px;
  margin-left: 25px;
  margin-right: 25px;
}

.gamedev-header {
  margin-bottom: 10px;
  max-width:100%;
  max-height:100%;
  width: auto;
}

.gamedev-screenshot {
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 4px;
  height: 45px;
  max-width:100%;
  max-height:100%;
  width: auto;
}

.keeping-view {
  max-width: 100vw;
}

.service-pic {
  max-width:100%;
  max-height:100%;
  width: auto;
}

.mmc-pic {
  width: 100%;
  margin-bottom: 4px;
}

.mmc-smaller {
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 4px;
  height: 65px;
  max-width:100%;
  max-height:100%;
  width: auto;
}

.mmc-small {
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 4px;
  height: 50px;
  max-width:100%;
  max-height:100%;
  width: auto;
}

.memorylist {
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  max-height: 375px;
  max-width: 100%;
}

.bg-memorylist {
  background-color: #202020;
}
