// Styling for the contact section
section#contact {
  background-color: #151515;
  background-image: url("#{$contact-image}");
  background-repeat: no-repeat;
  background-position: center;
  .section-heading {
    color: $white;
  }
  .form-group {
    margin-bottom: 25px;
    input,
    textarea {
      padding: 20px;
    }
    input.form-control {
      height: auto;
      background-color: #eeeeee;
    }
    textarea.form-control {
      height: 350px;
      background-color: #eeeeee;
    }
  }
  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }
  ::-webkit-input-placeholder {
    font-weight: 700;
    color: $gray-600;
    @include heading-font;
  }
  :-moz-placeholder {
    font-weight: 700;
    color: $gray-600;
    @include heading-font;
  }
  ::-moz-placeholder {
    font-weight: 700;
    color: $gray-600;
    @include heading-font;
  }
  :-ms-input-placeholder {
    font-weight: 700;
    color: $gray-600;
    @include heading-font;
  }
}
